<template>
  <mdb-container fluid class="p-0 classic-form-page">
    <mdb-navbar style="background-color: #7283a7;" class="justify-content-between">
      <mdb-navbar-brand href="/">
        <img src="/images/PLUSCREW-LOGO.png" style="width: 100px;" />
      </mdb-navbar-brand>
      <span class="navbar-text" style="color:#000;font-weight: bold;float: right; display:none;">
        <a href="javascript:;" v-on:click="changelang('fr')" style="color: #000; text-decoration: underline;">FR</a> | <a href="javascript:;" v-on:click="changelang('en')" style="color: #000;">EN</a>
      </span>
    </mdb-navbar>
    <mdb-view class="">
      <mdb-mask
        overlay="stylish-strong"
        class="d-flex justify-content-center"
      >
        <mdb-container class="pt-5">
          <mdb-row>
            <div class="col-xl-5 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5">
              <mdb-card id="classic-card">
                <mdb-card-body class="z-depth-2 white-text">
                  <div class="form-header" style="background-color: #1c2331;">
                    <h3><i class="fas fa-user mt-2 mb-2"></i> Change my password</h3>
                  </div>
                  <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
                  <mdb-input
                    label="Your new password"
                    labelColor="white"
                    type="password"
                    inputClass="input-form-control"
                    v-model="newpassword"
                    @keyup.enter.native="checkforenterpressed"
                    v-if="canchangemypassword"
                  />
                  <div class="text-center mt-4 black-text" v-if="canchangemypassword">
                    <mdb-btn style="background-color: #929fba!important;font-weight: bold;" class="black-text" v-on:click="reset">Save</mdb-btn>
                  </div>
                </mdb-card-body>
              </mdb-card>
            </div>
          </mdb-row>
        </mdb-container>
      </mdb-mask>
    </mdb-view>
  </mdb-container>
</template>

<script>
import {
  mdbNavbar,
  mdbNavbarBrand,
  mdbContainer,
  mdbView,
  mdbMask,
  mdbRow,
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbBtn,
  mdbAlert,
} from 'mdbvue';
import Apicall from '@/libs/Apicall';
import Auth from '../../libs/Auth';

export default {
  name: 'lostpasswordpage',
  data() {
    return {
      email: '',
      newpassword: '',
      error: '',
      canchangemypassword: false,
    };
  },
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbContainer,
    mdbView,
    mdbMask,
    mdbRow,
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbBtn,
    mdbAlert,
  },
  mounted() {
    this.loadChangePwdRequest();
  },
  methods: {
    loadChangePwdRequest() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/auth/validate-change-pwd-token', { token: this.$route.params.token }).then((response) => {
        if (response.status === 'OK') {
          self.canchangemypassword = true;
        } else {
          self.error = response.msg;
        }
      });
    },
    reset() {
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/auth/change-password-with-token', { token: this.$route.params.token, password: this.newpassword }).then((response) => {
        if (response.status === 'OK') {
          self.email = response.data.email;
          self.forcelogin();
        } else {
          this.error = response.msg;
        }
      });
    },
    forcelogin() {
      const self = this;
      const auth = new Auth();
      auth.login(this.email, this.newpassword).then((response) => {
        if (response.status === 'OK') {
          switch (self.$store.state.accesslevel) {
            case 'FULLACCESS':
              self.$router.push({ name: 'Admin' });
              break;
            default:
              self.$router.push({ name: 'Dashboard' });
              break;
          }
        } else {
          self.error = response.msg;
        }
      });
    },
    checkforenterpressed(e) {
      if (e.keyCode === 13) {
        this.login();
      }
    },
    changelang(lang) {
      this.$store.state.currentlang = lang;
      this.$i18n.locale = lang;
      window.localStorage.setItem('currentlang', lang);
    },
  },
};
</script>

<style scoped>
.classic-form-page {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  height: 100vh;
  width: 100%;
}

.classic-form-page .view {
  background-image: url("/images/login-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
}

.card {
  background-color: rgba(229, 228, 255, 0.2);
}

.classic-form-page h6 {
  line-height: 1.7;
}

.classic-form-page .navbar {
  transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.classic-form-page .navbar .md-form {
  margin: 0;
}

.top-nav-collapse {
  background: #424f95 !important;
}

@media (max-width: 768px) {
  .classic-form-page .navbar:not(.top-nav-collapse) {
    background: #424f95 !important;
  }
}

.classic-form-page label {
  color: #fff !important;
}

</style>
<style>
.input-form-control {
  color:#FFF!important;
}
</style>

<template>
  <mdb-container fluid>
    <Header></Header>
    <mdb-row v-if="error" class="justify-content-around pt-3" @click="error = null">
      <mdb-col md="6">
        <mdb-alert color="danger" >{{error}}</mdb-alert>
      </mdb-col>
    </mdb-row>
    <div v-for="(item, index) in allElements" :key="index">
      <video-review-panel v-if="item?.type==='video-file'" :task-id="$route.params.taskId" :deliveryIndex="index" class="mt-3"
        @error="error = $event" @change="error = null">
      </video-review-panel>
    </div>
  </mdb-container>
</template>

<script>
import {
 mdbAlert, mdbContainer, mdbCol, mdbRow,
} from 'mdbvue';
import Header from '@/components/Header.vue';
import VideoReviewPanel from '@/components/VideoReviewPanel.vue';
import Apicall from '@/libs/Apicall';

export default {
  components: {
 Header, mdbCol, mdbAlert, mdbContainer, mdbRow, VideoReviewPanel,
},
  data() {
    return {
      error: null,
      task: null,
      video: 'video',
    };
  },
  computed: {
    allElements() {
      if (!this.task) {
        return [];
      }

      let deliveryContent = null;

      if (this.task.spotcheckof) {
        deliveryContent = this.task.spotcheckof.deliverycontent;
      } else {
        deliveryContent = this.task.deliverycontent;
      }

      const allElements = deliveryContent;

      return allElements.length > 0 ? allElements : [];
    },
  },
  methods: {
    getInfos(taskId) {
      const apiCall = new Apicall();

      apiCall
        .call('POST', 'api/task/infos', { taskid: taskId })
        .then((response) => {
          if (response.status === 'OK') {
            this.task = response.data;
          } else {
            this.$emit('error', response.msg);
          }
        })
        .catch((error) => {
          this.$emit('error', error);
        });
    },
  },
  mounted() {
    if (this.$route.params.taskId) {
      this.getInfos(this.$route.params.taskId);
    }
  },
};
</script>

<style>

</style>
